/* eslint-disable max-len */
/*
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';

export const GithubLink = ({ width, height }) => {
  return (
    <a href="https://github.com/epam/mriviewer" target="_blank" rel="noreferrer">
      <svg width={width} height={height} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
        <g fill="#fff" fillRule="evenodd">
          <path d="m8.20003216 0c-4.5281696 0-8.20003216 3.67233045-8.20003216 8.2025137 0 3.6241466 2.34955472 6.6987785 5.60770169 7.7833978.40979259.0759104.5602814-.1779394.5602814-.3946059 0-.195566-.00765306-.84177-.01112588-1.5271516-2.28132027.4961843-2.76269151-.9677944-2.76269151-.9677944-.37300645-.948109-.91045728-1.2001576-.91045728-1.2001576-.74395492-.5091149.05607959-.4986933.05607959-.4986933.82344388.0578979 1.25703171.8453083 1.25703171.8453083.73134987 1.2540026 1.9182821.8914335 2.38621225.6819077.07357231-.5302154.28612167-.8920125.52060124-1.0968421-1.82130043-.2074672-3.73598109-.9107972-3.73598109-4.05374848 0-.89548638.32033536-1.62725057.84492391-2.20166134-.08514837-.20663091-.36580356-1.04087425.07942464-2.17071817 0 0 .68858276-.22046205 2.25559569.8408051.6540475-.18179917 1.35555686-.27289175 2.0524358-.27597964.69687893.00308789 1.39890278.09418047 2.05423654.27597964 1.5651479-1.06126715 2.252766-.8408051 2.252766-.8408051.4463215 1.12984392.1655376 1.96408726.0803893 2.17071817.5257461.57441077.8438949 1.30611063.8438949 2.20166134 0 3.15041368-1.9182821 3.84415838-3.74421295 4.04718668.29409628.2545575.55616545.7537654.55616545 1.5189817 0 1.0974854-.0095181 1.9808133-.0095181 2.2510031 0 .2182748.1475948.4740546.5633041.3935122 3.2563462-1.0858415 5.6029426-4.1593798 5.6029426-7.7823041 0-4.53018325-3.6713481-8.2025137-8.19996784-8.2025137" />
          <path
            d="m3.07518929 11.6824029c-.01787762.0408541-.08159856.0531168-.13950928.0251063-.05905999-.0268489-.09226129-.0826118-.07310669-.1236596.01755837-.0420803.08127932-.0538267.14021161-.025558.05918769.0267843.09289978.0830636.07240436.1241113m.40045868.3611685c-.03881997.0364008-.11473601.0194912-.16626186-.0380143-.05324977-.0573765-.06321015-.1340506-.02381554-.1710323.0400331-.0363363.11365058-.0193621.16702804.0380789.05324977.0580219.06359325.1342442.02298552.1710322m.27480455.4620452c-.04992964.0350455-.1315282.0021944-.18190478-.0709945-.04986579-.0731243-.04986579-.1608994.00108543-.1960739.05056812-.0351746.13088971-.0035497.18196863.0690583.04980194.0744151.04980194.1621902-.00121313.1980747m.46462657.5351695c-.0446302.0496962-.13963698.0364009-.209232-.0314957-.07112739-.0663476-.09098432-.1605121-.04622642-.2102728.04514099-.0498253.14072241-.03582.21082822.0314957.07068044.0662185.09226128.161093.04469405.2102728m.60036877.1807134c-.01960153.0644113-.11116048.0937127-.20335792.0663476-.09206974-.0282042-.15234286-.1037166-.1337629-.1688379.01915459-.0648632.11109663-.0953908.20399641-.0660894.09194204.0280751.15234286.1030065.13318826.1685797m.68337202.0766095c.00229855.0678966-.07591604.1241759-.17271058.1254021-.09736918.0021299-.17609455-.0527941-.17711613-.1195289 0-.068542.07642682-.124305.17373215-.1259185.09679454-.0019362.17609456.0526005.17609456.1200453m.67130462-.0260098c.01162045.0662185-.05567602.1342442-.15176823.1523155-.09449599.0174259-.18196862-.0234282-.19403602-.0890659-.01174815-.0678965.05682529-.1358576.15112974-.1534772.09628375-.0169096.18241557.0229119.19467451.0902276"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </a>
  );
};
