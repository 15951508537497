/*
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */

const Modes3d = {
  NA: -1,
  ISO: 0,
  RAYCAST: 1,
  RAYFAST: 2,
  EREASER: 3,
};

export default Modes3d;
